import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  Dimensions,
  GestureResponderEvent,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import tw from '../../../config/tailwind';
import { UpdateImageMarkupPayload } from '../../../shared/types/markup';
import uuidV4 from '../../../utils/uuidv4';
import InputLabel from '../../shared/InputLabel';
import InputText from '../../shared/InputText';
import P from '../../shared/P';
import Text from '../../shared/Text';
import InfoSVG from '../../svg/InfoSVG';
// import Image from '../../react-native-image';

interface MarkupEditorProps {
  image: string;
  markers?: UpdateImageMarkupPayload[];
  onChange?: (markers: UpdateImageMarkupPayload[]) => void;
  preview?: boolean;
  row?: boolean;
}

const MarkupEditor: React.FC<MarkupEditorProps> = ({
  image,
  onChange,
  markers: existingMarkers,
  preview,
  row,
}) => {
  return null
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(
    Dimensions.get('window').height - 80
  );
  const [markers, setMarkers] = useState<UpdateImageMarkupPayload[]>(
    existingMarkers || []
  );

  const [imageBounds, setImageBounds] = useState({ height: 0, width: 0 });
  const markerSize = 20;
  const [activeMarkerId, setActiveMarkerId] = useState('');
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    setContainerHeight(Dimensions.get('window').height - 80);
  }, []);

  useEffect(() => {
    setMarkers(existingMarkers || []);
  }, [existingMarkers]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
      return;
    }

    onChange && onChange(markers);
  }, [markers]);

  /**
   * Adds RAW calculation of x & y marker position based on imageBounds and GestureEvent location
   */
  const addMarker = (e: GestureResponderEvent) => {
    if (preview) return;
    const markerId = uuidV4();
    setMarkers((markers) => [
      ...markers,
      {
        id: markerId,
        comment: '',
        x: (e.nativeEvent.locationX / imageBounds.width) * 100,
        y: (e.nativeEvent.locationY / imageBounds.height) * 100,
      },
    ]);
    setActiveMarkerId(markerId);
  };

  return (
    <>
      {!preview && (
        <Text
          style={tw`py-2 px-3 mb-3 flex flex-nowrap items-center border-2 border-green`}
        >
          <InfoSVG style={tw`mr-3`} />
          Click on the image below to add comments
        </Text>
      )}
      <View style={tw.style(`flex relative`, row && 'flex-row')}>
        <View
          style={tw.style(`relative mb-3`, row && `w-1/2 mr-3`)}
          onLayout={(event) => {
            const { width } = event.nativeEvent.layout;
            setContainerWidth(width);
          }}
        >
          <View style={tw`flex flex-start `}>
            <TouchableWithoutFeedback
              onPress={addMarker}
              disabled={markers.length >= 3}
              style={tw`bg-red`}
            >
              <View style={tw`relative`}>
              {/* 
              
                <Image
                  height={containerHeight}
                  width={containerWidth}
                  source={{
                    uri: image,
                  }}
                  resizeMode='contain'
                  onSize={({ height, width }) => {
                    if (
                      height !== imageBounds.height ||
                      width !== imageBounds.width
                    ) {
                      setImageBounds({ height, width });
                    }
                  }}
                />
              */}
                {/*  Output marker points over the image */}
                {markers.map((marker, i) => {
                  return (
                    <Text
                      key={marker.id}
                      style={tw.style(
                        `bg-green rounded-full border-2 border-white z-10 flex items-center justify-center text-sm`,
                        {
                          boxShadow: '0 0 3px 1px rgba(0,0,0, 0.3)',
                          position: 'absolute',
                          height: markerSize,
                          width: markerSize,
                          top: `calc(${marker.y}% - ${markerSize / 2}px)`,
                          left: `calc((100% - ${Math.max(
                            0,
                            containerWidth - imageBounds.width
                          )}px) * ${Math.min(1, marker.x / 100)} - ${
                            markerSize / 2
                          }px)`,
                        }
                      )}
                    >
                      {i + 1}
                    </Text>
                  );
                })}
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>

        <View style={tw.style(row && `w-1/2`)}>
          {markers.map((marker, i) => (
            <>
              {preview ? (
                <View>
                  <View style={tw`flex flex-row flex-no-wrap justify-between`}>
                    <InputLabel label={`Comment ${i + 1}`} />
                  </View>
                  <P>{marker.comment || 'No comment'}</P>
                </View>
              ) : (
                <View>
                  <View style={tw`flex flex-row flex-no-wrap justify-between`}>
                    <InputLabel label={`Comment ${i + 1}`} />
                    <Text
                      onPress={() =>
                        setMarkers((markers) =>
                          markers.filter((_marker) => _marker.id !== marker.id)
                        )
                      }
                      style={tw.style(`underline text-red`, {
                        cursor: 'pointer',
                      })}
                    >
                      Delete Comment
                    </Text>
                  </View>
                  <InputText
                    value={marker.comment}
                    multiline
                    numberOfLines={2}
                    placeholder='Enter a comment'
                    setValue={(v) =>
                      setMarkers((markers) =>
                        markers.map((_marker) =>
                          _marker.id === marker.id
                            ? { ..._marker, comment: v }
                            : _marker
                        )
                      )
                    }
                  />
                </View>
              )}
            </>
          ))}
        </View>
      </View>
    </>
  );
};

export default MarkupEditor;
