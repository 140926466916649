import { differenceInCalendarQuarters } from 'date-fns';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { useCallback, useState } from 'react';
import { RebateClient } from '../../../api/RebateClient';
import Button from '../../../components/shared/Button';
import DefaultModal from '../../../components/shared/DefaultModal';
import InputText from '../../../components/shared/InputText';
import tw from '../../../config/tailwind';
import { useAuthContext } from '../../../hooks/useAuthContext';
import {
  Rebate,
  RebateApplication,
  RebateApplicationUpdatePayload,
  RebateStatus,
} from '../../../shared/types/rebates';
import { UserRole } from '../../../shared/types/users';
import RebateReapplyModal from './RebateReapplyModal';
import { displayQuarter } from './RebateFunction';

dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);

interface Props {
  rebate: Rebate | null;
  setRebate: (v: null) => void;
  refresh: () => void;
}

export const resolveFile = async (companyId: string, filename: string) => {
  // console.clear();
  const freshUrl = await RebateClient.getRebateFile(companyId, filename);
  // console.log('freshUrl data', freshUrl.data);
  const link = document.createElement('a');
  link.href = freshUrl.data;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const resolveFiles = async ({
  companyId,
  filenames,
}: {
  companyId: string;
  filenames: string[];
}) => {
  for await (const filename of filenames) {
    const freshUrl = await RebateClient.getRebateFile(companyId, filename);

    const link = document.createElement('a');
    link.href = freshUrl.data;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    await new Promise((resolve) => setTimeout(resolve, 300));
  }
};

export const blobUrl =
  process.env.STORAGE_ENDPOINT ??
  'https://zentiaportalstorage.blob.core.windows.net/';

const RebateDetailsModal: React.FC<Props> = ({
  rebate,
  setRebate,
  refresh,
}) => {
  const { user, activeCompany } = useAuthContext();
  const isPartners = user?.role === UserRole.User || activeCompany;
  const { applications } = rebate! || { applications: [] };
  const sortedApplications =
    applications.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1)) || [];
  const [confirmApprove, setConfirmApprove] =
    useState<RebateApplication | null>(null);
  const [confirmDecline, setConfirmDecline] =
    useState<RebateApplication | null>(null);

  const [showNewAppModal, setShowNewAppModal] = useState(false);

  return (
    <>
      <DefaultModal
        visible={!!rebate}
        setVisible={(v) => {
          setRebate(null);
        }}
        title={'Rebate Details'}
      >
        {rebate ? (
          <>
            <div style={tw`w-full flex flex-col font-sans`}>
              <p style={tw`flex justify-between`}>
                <b>Company:</b> {rebate.company.name}
              </p>
              <p style={tw`flex justify-between`}>
                <b>Quarter:</b> {displayQuarter(rebate.rebateDate)}
              </p>
              <p style={tw`flex justify-between`}>
                <b>Created At:</b>{' '}
                {dayjs(rebate.createdAt).format(`DD MMM YYYY`)}
              </p>
            </div>
            <div style={tw`w-full flex flex-col font-sans`}>
              <h3>Applications ({sortedApplications.length})</h3>
              {!sortedApplications.filter(
                (a) => a.status === 0 || a.status === 1
              ).length &&
              isPartners &&
              differenceInCalendarQuarters(
                new Date(),
                new Date(rebate.rebateDate)
              ) < (activeCompany?.rebateBackdate ? 4 : 2) ? (
                <Button onPress={() => setShowNewAppModal(true)}>
                  Apply with new evidence
                </Button>
              ) : null}
              <div style={tw`flex flex-col`}>
                {sortedApplications.map((x) => (
                  <div
                    key={`rebate-${rebate.id}-application-${x.id}`}
                    style={tw`p-4 bg-[#ddd] my-2 flex flex-row justify-between`}
                  >
                    <div>
                      <p>
                        <b>Status:</b> {RebateStatus[x.status]}
                      </p>
                      <p>
                        <b>Rebate Excel:</b>{' '}
                        <a
                          style={tw`underline cursor-pointer`}
                          href='#'
                          onClick={() => {
                            const filename =
                              x.evidenceUrl.split('?')[0].split('/').pop() ??
                              '';
                            resolveFile(rebate.company.id, filename);
                          }}
                        >
                          Download
                        </a>
                      </p>
                      {!x.supportingEvidences?.length ? (
                        <></>
                      ) : (
                        <div>
                          <b style={tw`block`}>All Evidences:</b>
                          <div style={tw`flex flex-col p-4 bg-[#fefefe] mt-4`}>
                            {x.supportingEvidences.map((e, i) => (
                              <a
                                key={`rebate-${rebate.id}-application-${x.id}-evidence-${e.id}-index-${i}`}
                                href={'#'}
                                onClick={() => {
                                  const filename =
                                    e.url.split('?')[0].split('/').pop() ?? '';

                                  resolveFile(
                                    rebate?.company?.id ?? '',
                                    filename
                                  );
                                }}
                                // target={'_blank'}
                                // download
                                style={tw`p-2 underline`}
                              >
                                {e.name}
                              </a>
                            ))}

                            {x.supportingEvidences.length > 1 ? (
                              <div style={tw`my-5`}>
                                <a
                                  href='#'
                                  style={tw`bg-green text-dark p-3 block font-semibold`}
                                  onClick={async () => {
                                    await resolveFiles({
                                      companyId: rebate.company.id,
                                      filenames: x.supportingEvidences.map(
                                        (ev) =>
                                          ev.url
                                            .split('?')[0]
                                            .split('/')
                                            .pop() ?? ''
                                      ),
                                    });
                                  }}
                                >
                                  Download All Evidences
                                </a>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                      <p>
                        <b>Applicant:</b>
                        {` ${x.applicant.firstName} ${x.applicant.lastName}`}
                      </p>
                      {x.reviewer ? (
                        <p>
                          <b>Reviewer:</b>
                          {` ${x.reviewer.firstName} ${x.reviewer.lastName}`}
                        </p>
                      ) : null}
                      <p>
                        <b>Recent Updated:</b>{' '}
                        {dayjs(x.createdAt).format('DD MMM YYYY')}
                      </p>
                      {x.status === 2 ? (
                        <div>
                          <b>Comment from reviewer:</b>
                          <br />
                          <p>{x.comment}</p>
                        </div>
                      ) : null}
                    </div>
                    {user?.role === UserRole.Admin &&
                    x.status === 0 &&
                    !activeCompany ? (
                      <div>
                        <Button
                          style={`min-h-[1rem] mb-2 py-2`}
                          onPress={() => {
                            setConfirmApprove({ ...x });
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          style={`min-h-[1rem] mb-2 py-2`}
                          variant={'danger'}
                          onPress={() => {
                            setConfirmDecline({ ...x });
                          }}
                        >
                          Decline
                        </Button>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : null}
      </DefaultModal>

      <ConfirmApproveModal
        application={confirmApprove}
        setApplication={(a) => setConfirmApprove(null)}
        refresh={refresh}
      />

      <ConfirmDeclineRebate
        application={confirmDecline}
        setApplication={(a) => setConfirmDecline(null)}
        refresh={refresh}
      />

      <RebateReapplyModal
        rebate={rebate}
        visble={showNewAppModal}
        setVisible={(v) => setShowNewAppModal(v)}
        refresh={refresh}
      />
    </>
  );
};

export default RebateDetailsModal;

interface ApproveModalProps {
  application: RebateApplication | null;
  setApplication: (a: RebateApplication | null) => void;
  refresh: () => void;
}

const ConfirmApproveModal: React.FC<ApproveModalProps> = ({
  application,
  setApplication,
  refresh,
}) => {
  const handleApprove = useCallback(async () => {
    if (!application) {
      return;
    }
    const { id } = application;
    const status = 1;
    const payload: RebateApplicationUpdatePayload = {
      id,
      status,
    };
    const res = await RebateClient.updateApplication(payload);
    console.log('approved application');
    refresh();
    setApplication(null);
  }, [application]);

  return (
    <>
      <DefaultModal
        visible={!!application}
        setVisible={() => setApplication(null)}
        title={`Approve Application`}
      >
        {application && (
          <div style={tw`font-sans`}>
            <p>Confirm to Approve this application?</p>
            <div style={tw`flex justify-center`}>
              <Button
                style={`mx-2`}
                onPress={() => {
                  handleApprove();
                }}
              >
                Approve
              </Button>
              <Button
                style={tw`mx-2`}
                variant={'danger'}
                onPress={() => setApplication(null)}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </DefaultModal>
    </>
  );
};

interface DeclineModalProps {
  application: RebateApplication | null;
  setApplication: (a: RebateApplication | null) => void;
  refresh: () => void;
}

const ConfirmDeclineRebate: React.FC<DeclineModalProps> = ({
  application,
  setApplication,
  refresh,
}) => {
  const [comment, setComment] = useState('');

  const handleDecline = useCallback(async () => {
    if (!application || !comment) {
      return;
    }
    const { id } = application;
    const status = 2;
    const payload: RebateApplicationUpdatePayload = {
      id,
      status,
      comment,
    };
    const res = await RebateClient.updateApplication(payload);
    console.log('declined application');
    refresh();
    setApplication(null);
  }, [application, comment]);

  return (
    <>
      <DefaultModal
        visible={!!application}
        setVisible={() => setApplication(null)}
        title={'Decline Rebate Application'}
      >
        {application ? (
          <div style={tw`font-sans`}>
            {/* <h4>Approve Application</h4> */}
            <p>Confirm to Decline this application?</p>
            <p style={tw`text-green`}>
              {displayQuarter(application.rebate?.rebateDate)} for{' '}
              {application.rebate?.company.name}
            </p>
            <div style={tw`flex flex-col`}>
              <InputText
                value={comment}
                setValue={(v) => setComment(v)}
                placeholder='Reason of decline'
                label='Please provide decline reason'
                required
                multiline
              />
            </div>
            <div style={tw`flex justify-center`}>
              <Button
                style={`mx-2`}
                onPress={() => handleDecline()}
                disabled={!comment}
              >
                Decline
              </Button>
              <Button
                style={`mx-2`}
                variant={'danger'}
                onPress={() => setApplication(null)}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : null}
      </DefaultModal>
    </>
  );
};
