import { View } from 'react-native';
import Button from '../../../components/shared/Button';
import FilePicker from '../../../components/shared/FilePicker';
import H3 from '../../../components/shared/H3';
import tw from '../../../config/tailwind';

interface Props {
  files: File[];
  addFiles: (v:File[] | undefined) => void;
  removeFiles: (i:number) => void;
  error?: string;
}

const RebateSupportingEvidencesUploader: React.FC<Props> = ({
  files,
  addFiles, removeFiles,
  error
}) => {
  return (
    <View
      style={tw`p-4 bg-[#eee]`}
    >
      {/* Listing of uploaded files */}
      <H3>Upload your Evidences</H3>
      <View
        style={tw`flex flex-col`}
      >
        {!files.length ? (
          <p>No supporting files</p>
        ) : (
          files.map((f, idx) => (
            <div 
              key={`supporting-files-item-${f.name}-${idx}`}
              style={tw`flex flex-row justify-between items-center align-center bg-white px-4 mb-2`}
            >
              <p style={tw`w-1/2`} >{f.name}</p>
              <Button variant='danger' style={`min-h-[24px] h-[24px] self-center`} 
                onPress={() => removeFiles(idx)}
              >Remove</Button>
            </div>
          ))
        )}
      </View>
      {/* Uploader */}
      <View>
        <FilePicker
          type={[
            `application/pdf`,
          ]}
          setValues={(v) => addFiles(v)}
          label='Select File to upload'
          uploadOnly={true}
          multiple
        />
        {error ? <p style={tw`text-red`}>{error}</p> : null}
      </View>
    </View>
  );
};

export default RebateSupportingEvidencesUploader;
