import * as DocumentPicker from 'expo-document-picker';
import { DocumentPickerSuccessResult } from 'expo-document-picker';
import { FC, useMemo, useState } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';

import tw from '../../config/tailwind';
import { useToasts } from '../../hooks/useToasts';
import CrossSVG from '../svg/CrossSVG';
import Button from './Button';
import Text from './Text';
import { useDropzone } from "react-dropzone"; 

const accepts = {
  "image/*": [".png", ".jpg", ".jpeg"],
  "application/pdf": [".pdf"],
  "application/vnd.ms-excel": [".xls", ".xlsx"]
}

type Props = {
  setValue?: (v?: File) => void;
  setValues?: (v: File[]) => void;
  label?: string;
  multiple?: boolean;
  type: '*/*' | keyof typeof accepts | string[];
  loading?: boolean;
  hasError?: boolean;
  uploadOnly?: boolean;
};



const FilePicker: FC<Props> = ({
  setValue,
  setValues,
  label,
  multiple,
  type,
  loading,
  hasError,
  uploadOnly
}) => {
  const [files, setFiles] = useState<DocumentPicker.DocumentPickerAsset[]>();
  const { addUnhandledErrorToast } = useToasts();

  const openDocumentPicker = async () => {

    const result = await DocumentPicker.getDocumentAsync({
      type,
      multiple
    });

    if(!result.canceled){
      const { assets } = result;
      setFiles(assets)
      if(!!setValue){
        setValue(assets?.[0]?.file)
      }
      if(!!setValues){
        setValues(assets.filter(aa => !!aa.file).map(aa => aa.file as File))
      }
    }
  };

  const removeFile = () => {
    setFiles(undefined);
    setValue && setValue(undefined);
  };

  const onDrop = (acceptedFiles: File[]) => {
    console.log("Dropped files:", acceptedFiles);
    // Handle file uploads here
    if(!!setValue && acceptedFiles.length){
      setValue(acceptedFiles[0])
    }
    if(!!setValues){
      setValues(acceptedFiles.filter(aa => !!aa).map(aa => aa as File))
    }
  };

  const dropzoneAccepts = useMemo(() => {
    const final = Object.fromEntries(
      Object.entries(accepts).filter(([key]) => {
        if(Array.isArray(type)){
          return type.includes(key)
        }
        return type === key;
      })
    )
    return final;
  }, [type])

  console.log('dropzoneAccepts', dropzoneAccepts)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: dropzoneAccepts ?? undefined,
  });

  return (
    <View
      style={tw.style(
        `border-2 border-border border-dashed px-8 py-16 my-4 flex justify-center items-center`,
        hasError && 'border-red'
      )}
    >
      {loading ? (
        <ActivityIndicator />
      ) : uploadOnly ? (
        <View >
          <div 
            {...getRootProps()}
            style={tw`p-4 mb-4 border-dashed border-slate-300 border-2 text-center`}
          >
            <h3>Drop Files Here</h3>
            <input {...getInputProps()} />
          </div>
          <Button outlined style={tw`self-center`} onPress={openDocumentPicker}>
            {label || 'Select document'}
          </Button>
        </View>
      ) : files?.length ? files.map((file, index) => (
        <Text style={tw`flex items-center justify-center`} key={`file-picker-files-index-${index}`} >
          {file.name}{' '}
          <Pressable onPress={removeFile}>
            <CrossSVG
              width={24}
              height={24}
              color='currentColor'
              style={tw`ml-2 text-red`}
            />
          </Pressable>
        </Text>
      )) : (
        <Button outlined style={tw`self-center`} onPress={openDocumentPicker}>
          {label || 'Select document'}
        </Button>
      )}
    </View>
  );
};

export default FilePicker;
